import state from './user.state.js'
import mutations from './user.mutations.js'
import actions from './user.actions.js'
import getters from './user.getters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

