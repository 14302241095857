import axios from '@/libs/axios.js'
let accessToken = localStorage.getItem('accessToken');

export default {
  userAuth ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/user/auth/admin', item).then((response) => {
        // console.log('>>>>> -- status -- <<<<<')
        // console.log(response.data.status)
        commit('USER_AUTH', Object.assign(response.data));
        resolve(response.data)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },
  userCreate ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/user/register', item).then((response) => {
        // console.log('>>>>> -- status -- <<<<<')
        // console.log(response.data.status)
        commit('USER_CREATE', Object.assign(response.data));
        resolve(response.data)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },
  userUpdate ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/user/update', item).then((response) => {
        // console.log('>>>>> -- status -- <<<<<')
        // console.log(response.data.status)
        commit('USER_CREATE', Object.assign(response.data));
        resolve(response.data)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },
  save ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(payloads.path + '/save', payloads.formdata, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          commit('USER_CREATE', Object.assign(response.data.data, {slug: response.data.data.slug}));
          resolve(response.data.data)
        } else {
          console.log('rejected from then')
          reject(response.data)
        }
      }).catch((error) => {
        console.log('rejected from catch')
        if (error.response) {
          let data = error.response.data;
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },
  edit ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.put(payloads.path + '/update', payloads.formdata, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        console.log(response)
        if(response.status === 200) {
          
          commit('USER_UPDATE', response.data.data);
          resolve(response.data.data)
        } else {
          
          reject(response.data)
        }
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  importItem ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(payloads.path + '/import', payloads.formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        //commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
        resolve(response.data.data)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetch ({ commit }, paths) {
    return new Promise((resolve, reject) => {
      axios.get(paths + '/select', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          commit('USER_LIST', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('USER_LIST', []);
          reject([]) 
        })
    })
  },
  
  remove ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.delete(payloads.path + '/remove/' + payloads.item, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        commit('USER_REMOVE', payloads.item);
        resolve(response)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetchItem (context, payloads) {
    return new Promise((resolve, reject) => {
      axios.get(`/${payloads.path}/select/${payloads.slug}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if(response.status === 200) {
            resolve(response.data.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
}
