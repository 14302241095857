export default {
  USER_AUTH (state, item) {
    state.user = item
  },
  USER_CREATE (state, item) {
    state.user = item
  },
  USER_UPDATE (state, payload) {
    const dataIndex = state.user.findIndex((p) => p.slug === payload.slug);
    Object.assign(state.user[dataIndex], payload)
  },
  USER_REMOVE (state, itemId) {
    const ItemIndex = state.user.findIndex((p) => p.slug === itemId);
    state.user.splice(ItemIndex, 1)
  },
  USER_LIST (state, payload) {
    state.user = payload
  },
}
